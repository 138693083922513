// import type { ThemeConfig } from "antd";

export const antdTheme  = {
  token: {
    colorPrimary:'#9b7c27',
    controlHeight: 45,
  },
  components: {
    Collapse: {
      contentBg : 'red',
      contentPadding : 0,
      headerPadding : 0
    },

  },
};
