import React, { useEffect, useState } from "react";
import Navbar from "../../components/navigation/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Marquee from "react-fast-marquee";
import { BsGraphDownArrow, BsGraphUpArrow } from "react-icons/bs";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";

const HomePageIndex = () => {
  const [allStockData, setallStockData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  
  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.GetAllStocks();
      const mappedData = res?.data
      .filter((value) => value.change && value.percentageChange !== 0)
      .filter((item, index, self) => 
        index === self.findIndex((t) => (t.symbol === item.symbol))
      )
      .map((value) => {
        return {
          ...value,
          key: value.id,
        };
      });
      setallStockData(mappedData);
      console.log(mappedData)

      setIsloading(false);
    } catch (error) {
      setallStockData([]);

      setIsloading(false);
      NotificationService.show(error.message, "error");
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const Marquee1 = ({ value }) => {
    // console.log(value);
    return (
      <div className="flex justify-center items-center gap-2 font-normal text-white mx-8 ">
        <p> {value?.symbol}</p>
        <p
          className={`${
            value.percentageChange < 0
              ? "text-[red] flex justify-center items-center"
              : value.percentageChange >= 0
              ? "text-[green] flex justify-center items-center"
              : ""
          }`}
        >
          {value.percentageChange < 0 ? (
            <span>
              <BsGraphDownArrow />
            </span>
          ) : value.percentageChange >= 0 ? (
            <span>
              <BsGraphUpArrow />
            </span>
          ) : null}
          {value?.percentageChange}%
        </p>
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      <Outlet />
      <div className="flex justify-center items-center !fixed !bottom-0  w-full h-[50px]">
        <p className="bg-[#5c2424] font-bold text-white px-1 py-2 md:px-2 md:py-4 h-full w-[35%] md:w-[10%]">
          Stock Price
        </p>
        <Marquee className="bg-[#001529] h-full w-full" pauseOnHover="true">
          {allStockData.map((value, index) => {
            return <Marquee1 key={index} value={value} />;
          })}
        </Marquee>
      </div>
      <Footer />
    </div>
  );
};

export default HomePageIndex;
