import React from 'react'
import { BsFillCaretUpFill } from 'react-icons/bs'
import { FaPlay } from 'react-icons/fa'

const BusinessNews = ({businessNews}) => {
    const { dateCreated,description,title } = businessNews;
    

  const formattedDate = new Date(dateCreated)
    .toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
    .toUpperCase();
  return (
   
       <div className=" w-full ">
            <p className="border flex w-full md:gap-3 bg-[#b49132] text-white py-2 md:py-3 px-1 text-sm mb-5">
              <span className="font-bold flex justify-center items-center gap-1 ">
                <FaPlay /> {formattedDate}{" "} : 
              </span>
             {title}
            </p>
            <div className=" relative">
              <span className="  top-[-20px] left-[50%] absolute z-[999] text-[#1F2134]   lg:text-[30px] text-[24px] ">
                <BsFillCaretUpFill />
              </span>
              {/* <Carousel className="w-full  text-white "> */}
                <div className=" w-full h-[200px] bg-[#1F2134] ">
                  <p className=" h-full p-5">
                {description}
                  </p>
                </div>
              
              {/* </Carousel> */}
            </div>
          </div>
   
  )
}

export default BusinessNews
