import { Route, Routes } from "react-router-dom";
import ResearchParentPage from "./ResearchParentPage";
import CurrentPriceList from "./CurrentPriceList";
import TopGainers from "./TopGainers";
import MarketResearchPage from "./MarketResearchPage";
import Toplosers from "./TopLosser";

const ResearchHomePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ResearchParentPage />}>
        <Route index element={<CurrentPriceList />} />
        <Route path="top_gainers" element={<TopGainers />} />
        <Route path="top_losers" element={<Toplosers />} />
        <Route path="Company_Research" element={<MarketResearchPage />} />
      </Route>
    </Routes>
  );
};

export default ResearchHomePage;
