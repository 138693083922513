import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;


const apiInstance = axios.create({
    baseURL : baseUrl || '',
    timeout: 60000,
    // headers : {
    //     Authorization : `${localStorage.getItem('userToken')}`
    //  }
})

export default apiInstance