import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";

const LatestReport = ({ latestReports }) => {
  const { videoUrl,description,title } = latestReports;
  return (
    <div className=" flex justify-center items-center md:flex-row flex-col bg-[#424242] w-full  ">
      <iframe
        width="200"
        height="200"
        src={videoUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className=" lg:w-[50%] w-full "
      ></iframe>

      <div className="flex justify-between items-center flex-col bg-white gap-5 md:pl-3  w-full min-h-[200px]  ">
        <div className="flex flex-col justify-start gap-2 items-start p-2 text-justify">
          <p className="text-[#b6932f] text-[16px] font-[500]  capitalize">
            {title}
          </p>
          <p className="text-[15px] text-[#424242] ">{description}</p>
        </div>

        <div className=" bg-[#1F2134] gap-2 flex justify-center items-center md:flex-col lg:flex-row flex-row text-white w-full px-1">
          <button className="bg-[#B49132] hover:bg-[#9b7c27] flex gap-1 justify-center items-center    font-[500] p-2   my-2 w-full   text-[15px]">
            Read reports
            <span>
              <AiOutlineArrowRight />
            </span>
          </button>
          <button className="bg-[#B49132] hover:bg-[#9b7c27] flex gap-1 justify-center items-center    font-[500] p-2   my-1 w-full   text-[15px]">
            Recent reports
            <span>
              <AiOutlineArrowRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestReport;
