import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Upload,
  Steps,
  message,
  Checkbox,
  Spin,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./individual.css";
import { Link, useLocation } from "react-router-dom";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import { GrValidate } from "react-icons/gr";
import { imageUpload } from "../../helpers/helpers";
import { CiCircleCheck } from "react-icons/ci";
const { Option } = Select;

const IndividualAccount = () => {
  const location = useLocation();

  const [current, setCurrent] = useState(0);
  const [items, setItems] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [secondFormDetails, setSecondFormDetails] = useState({});
  const [allCountry, setAllCountry] = useState([]);
  const [allState, setallState] = useState([]);
  const [AllBanks, setAllBanks] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [uploadedimage, setUploadedImage] = useState(null);
  const [UploadedbirthCert, setUploadedbirthCert] = useState(null);
  const [uploadedIdCard, setUploadedIdCard] = useState(null);
  const [uploadedUtils, setuploadedUtils] = useState(null);
  const [uploadedSignature, setuploadedSignature] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const currentDateTime = new Date().toISOString();
  const [birthCertificateUrl, setBirthCertificateUrl] = useState("");
  const [idCardUrl, setIdCardUrl] = useState("");
  const [utilityBillUrl, setUtilityBillUrl] = useState("");
  const [signatureUrl, setSignatureUrl] = useState("");
  const [personalDetailsId, setpersonalDetailsId] = useState(0);
  const fetch = async () => {
    setisloading(true);
    try {
      const res = await adminServices.getAllCountry();
      const stateRes = await adminServices.getAllState();
      const bankRes = await adminServices.getAllBanks();
      setAllCountry(res.data);
      setallState(stateRes.data);
      setAllBanks(bankRes.data);
      setisloading(false);
    } catch (error) {
      setisloading(false);
      NotificationService.show(error.message, "error");
    }
  };

  const handleImageUpload = (info, setUploadedImage) => {
    //console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
      // console.log(file);
      setUploadedImage(file);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async (values) => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      delete values.photo55;
      delete values.photo5;
      delete values.photo6;
      delete values.photo7;
      delete values.photo8;
      try {
        const res = await adminServices.personalDetails({
          ...values,
          birthCertificateUrl: birthCertificateUrl,
          idCardUrl: idCardUrl,
          utilityBillUrl: utilityBillUrl,
          signatureUrl: signatureUrl,
          passport: imagePath,
          companyId: 1,
          formTypeId: 1,
        });
        setisloading(false);
        setpersonalDetailsId(res?.data);
        setCurrent(current + 1);
      } catch (error) {
        setisloading(false);
        NotificationService.show(error.message, "error");
      }
    }
  };

  const onFinishKyc = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      try {
        const res = await adminServices.PostKycDetails({
          ...values,
          personalDetailsId: personalDetailsId,
        });
        setisloading(false);
        setCurrent(current + 1);
      } catch (error) {
        setisloading(false);
        NotificationService.show(error.message, "error");
      }
    }
  };

  const onFinishBankDetails = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      delete values.terms;
      try {
        const res = await adminServices.PostBankDetails({
          ...values,
          personalDetailsId: personalDetailsId,
        });
        setisloading(false);
        setIsModalOpen(true);
      } catch (error) {
        setisloading(false);
      }
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onsubmitHandler = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      try {
        delete values.photo55;
        delete values.terms;
        delete formDetails.photo55;
        delete formDetails.terms;
        setisloading(true);
        const res = await adminServices.createIndividualForm({
          details: [
            {
              ...formDetails,
              ...values,
              uploadPix: imagePath,
            },
          ],
        });
        //console.log(res);
        if (res.status === 200) {
          // let newId = parseInt(res?.data?.split("=").pop());
          try {
            const AccountOpeningRes = adminServices.AccountOpening({
              formTypeId: 1,
              personalDetailsId: res?.data,
              companyId: 1,
              dateCreated: currentDateTime,
            });
            //console.log(AccountOpeningRes);
          } catch (error) {
            //console.log(error);
          }
        }
        //console.log(res.status);
        // setCurrent(current + 1);
        setIsModalOpen(true);
        setisloading(false);
      } catch (error) {
        setisloading(false);
        //console.log(error);
        NotificationService.show(error.message, "error");
      }
    }
  };

  const OnCancelHandler = () => {
    setIsModalOpen(false);
    form.resetFields();
    setCurrent(0);
  };

  const steps = [
    {
      title: "Personal Details",
      content: (
        <Form
          form={form}
          // initialValues={initialValues}
          onFinish={onFinish}
          layout="vertical"
          className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
        >
          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Enter Surname Name" }]}
              className="mb-1 flex-1"
              name="surname"
              label="Surname"
            >
              <Input placeholder="Surname" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter First Name" }]}
              className="mb-1 flex-1"
              name="firstName"
              label="First Name"
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter Other Names" }]}
              className="mb-1 flex-1"
              name="middleName"
              label="Other Names"
            >
              <Input placeholder="Other Names" />
            </Form.Item>
          </div>

          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Select Gender" }]}
              className="mb-1 flex-1"
              name="genderId"
              label="Gender"
            >
              <Select placeholder="Select Gender">
                <Option value={1}>Male</Option>
                <Option value={2}>Female</Option>
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Enter Date Of Birth" }]}
              className="mb-1 flex-1 text-black"
              name="dateofBirth"
              label="Date of Birth"
            >
              <DatePicker style={{ width: "100%" }} className="text-black" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: "Enter Mother’s Maiden Name" },
              ]}
              className="mb-1 flex-1"
              name="maidenName"
              label="Mother’s Maiden Name"
            >
              <Input placeholder="Mother’s Maiden Name" />
            </Form.Item>
          </div>

          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Enter phone number" }]}
              className="mb-1 flex-1"
              name="phoneNumber"
              label="Telephone"
            >
              <Input placeholder="Telephone" type="number" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Enter Occupation" }]}
              className="mb-1 flex-1"
              name="occupation"
              label="Occupation"
            >
              <Input placeholder="Occupation" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter Email" }]}
              className="mb-1 flex-1"
              name="email"
              label="E-mail address"
            >
              <Input placeholder="E-mail address" type="email" />
            </Form.Item>
          </div>
          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Select Country" }]}
              className="mb-1 flex-1"
              name="countryId"
              label="Nationality"
            >
              <Select
                allowClear
                optionFilterProp="children"
                placeholder="Nationality"
                className="text-gray-600"
              >
                {allCountry.map(({ id, name }, index) => (
                  <Select.Option value={id} key={index}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Select State" }]}
              className="mb-1 flex-1"
              name="stateId"
              label="State of Origin"
            >
              <Select
                allowClear
                optionFilterProp="children"
                placeholder="State of Origin"
                className="text-gray-600"
              >
                {allState.map(({ id, name }, index) => (
                  <Select.Option value={id} key={index}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Enter LGA" }]}
              className="mb-1 flex-1"
              name="city"
              label="LGA"
            >
              <Input placeholder="LGA" />
            </Form.Item>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Enter ID Type" }]}
              className="mb-1 flex-1"
              name="idCardType"
              label="ID Type"
            >
              <Input placeholder="NIN" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "upload passport" }]}
              className="mb-1 flex-1"
              name="photo5"
              label="Upload Means of Identification"
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setUploadedIdCard)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload ID Card</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Enter ID No." }]}
              className="mb-1 flex-1"
              name="idCardNumber"
              label="ID No."
            >
              <Input placeholder="ID No." type="number" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter Expiry Date" }]}
              className="mb-1 flex-1"
              name="idCardExpiryDate"
              label="Expiry Date"
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "Enter your address" }]}
              className="mb-1 flex-1"
              name="address"
              label="Residential Address"
            >
              <Input.TextArea placeholder="Residential Address" />
            </Form.Item>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-10 justify-between items-center my-5">
            <Form.Item
              rules={[{ required: true, message: "upload passport" }]}
              className="mb-1 flex-1"
              name="photo55"
              label="Passport Photograph"
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setUploadedImage)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload Photo</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "upload birth certificate" }]}
              className="mb-1 flex-1"
              name="photo6"
              label="Birth Certificate "
            >
              <Upload
                onChange={(file) =>
                  handleImageUpload(file, setUploadedbirthCert)
                }
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>
                  Upload Birth Certifcate
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "upload utility bill" }]}
              className="mb-1 flex-1"
              name="photo7"
              label="Valid Utility Bill "
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setuploadedUtils)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload utility bill</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Upload your signature" }]}
              className="mb-1 flex-1"
              name="photo8"
              label="Upload your signature"
            >
              <Upload
                onChange={(file) =>
                  handleImageUpload(file, setuploadedSignature)
                }
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload your signature</Button>
              </Upload>
            </Form.Item>
          </div>
          <Form.Item
            rules={[{ required: true, message: "Enter Date Of Birth" }]}
            className="mb-1 flex-1"
          >
            <Button
              htmlType="submit"
              className="bg-[#001529] text-white w-[30%] border my-5 "
              // onClick={()=> onFinish2()}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      ),
      icon:
        current > 0 ? (
          <CiCircleCheck />
        ) : (
          <div className="border border-[#001529] p-[2px] !rounded-[100%]">
            <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
              1
            </p>
          </div>
        ),
    },
    {
      title: (
        <p>
          {location.pathname === "/Minor"
            ? "Guardians Details"
            : location.pathname === "/IndividualAccount"
            ? "Next of Kin Details"
            : null}
        </p>
      ),
      content: (
        <Form
          form={form}
          // initialValues={initialValues}
          onFinish={() => onFinishKyc()}
          layout="vertical"
          className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
        >
          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "next of kins's name" }]}
              className="mb-1 flex-1"
              name="name"
              label="Next of Kin’s Name"
            >
              <Input placeholder="Next of Kin’s Name" />
            </Form.Item>
          </div>
          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[
                { required: true, message: "next of kins's relationship" },
              ]}
              className="mb-1 flex-1"
              name="relationship"
              label="Relationship"
            >
              <Input placeholder="Relationship" />
            </Form.Item>

            <Form.Item
              rules={[
                { required: true, message: "next of kins's phone number" },
              ]}
              className="mb-1 flex-1"
              name="phoneNumber"
              label="next of kin's Telephone"
            >
              <Input placeholder="Telephone" type="number" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "next of kins's occupation" }]}
              className="mb-1 flex-1"
              name="occupation"
              label="Next of Kin’s Occupation"
            >
              <Input placeholder="Next of Kin’s Occupation" />
            </Form.Item>
          </div>
          <div className="flex gap-3 flex-col md:flex-row ">
            <Form.Item
              rules={[{ required: true, message: "next of kin's Address" }]}
              className="mb-1 flex-1"
              name="address"
              label="next of kin's Address"
            >
              <Input.TextArea placeholder="Contact Address" />
            </Form.Item>
          </div>

          <Form.Item
            rules={[{ required: true, message: "Enter Date Of Birth" }]}
            className="mb-1 flex-1"
          >
            <Button
              htmlType="submit"
              className="bg-[#001529] text-white w-[30%] border my-5 "
              // onClick={()=> onFinish2()}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      ),
      icon:
      current > 1 ? (
        <CiCircleCheck />
      ) : (
        <div className="border border-[#001529] p-[2px] !rounded-[100%]">
          <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
            2
          </p>
        </div>
      ),
    },
    {
      title: "Bank Details",
      content: (
        <>
          <Form
            form={form}
            // initialValues={initialValues}
            onFinish={() => onFinishBankDetails()}
            layout="vertical"
            className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
          >
            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "CHN NO" }]}
                className="mb-1 flex-1"
                name="chn"
                label="CHN NO"
              >
                <Input placeholder="CHN NO" type="number" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter BVN NO" }]}
                className="mb-1 flex-1"
                name="bvn"
                label="BVN NO"
              >
                <Input placeholder="BVN NO" type="number" />
              </Form.Item>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter Name of Bank" }]}
                className="mb-1 flex-1"
                name="bankId"
                label="Name of Bank"
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Banks"
                  className="text-gray-600"
                >
                  {AllBanks.map(({ id, bankName }, index) => (
                    <Select.Option value={id} key={index}>
                      {bankName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter Bank Account No" }]}
                className="mb-1 flex-1"
                name="accountNumber"
                label="Bank Account No."
              >
                <Input placeholder="Bank Account No." type="number" />
              </Form.Item>
            </div>
            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter Bank Account Name" }]}
                className="mb-1 flex-1"
                name="accountName"
                label="Bank Account Name"
              >
                <Input placeholder="Bank Account Name" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter Date Of Birth" }]}
                className="mb-1 flex-1"
                name="accountType"
                label="A/C Type"
              >
                <Input placeholder="savings" />
              </Form.Item>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[
                  { required: true, message: "Enter Date of Account Created" },
                ]}
                className="mb-1 flex-1"
                name="dateOfCreation"
                label="Date of Account Created"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Bank Short Code" }]}
                className="mb-1 flex-1"
                name="bankShortCode"
                label="Bank Short Code"
              >
                <Input placeholder="Bank Short Code" />
              </Form.Item>
            </div>
            <Form.Item
              rules={[{ required: true, message: "bank branch address" }]}
              className="mb-1 flex-1"
              name="branchAddress"
              label="bank branch address"
            >
              <Input.TextArea placeholder=" Address" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Terms and Condition" }]}
              className="mb-1 flex-1"
              name="terms"
              label=""
            >
              <div className="flex gap-3">
                <Checkbox />
                <p>
                  I agree with the{" "}
                  <span>
                    <Link to={"/Terms&Condition"}>Terms and Conditions</Link>{" "}
                  </span>
                </p>
              </div>
            </Form.Item>
            {/* <Form.Item rules={[{ required: true }]} className="mb-1 flex-1"> */}
            <Button
              className="bg-[#001529] text-white w-[30%] border my-5 "
              htmlType="submit"
            >
              Submit
            </Button>
            {/* </Form.Item> */}
          </Form>
        </>
      ),
      icon:
      current > 2 ? (
        <CiCircleCheck />
      ) : (
        <div className="border border-[#001529] p-[2px] !rounded-[100%]">
          <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
            3
          </p>
        </div>
      ),
    },
  ];


  const theItem = (arr) =>
    arr.map((item) => ({
      key: item.title,
      title: item.title,
      icon: items.icon,
    }));

  useEffect(() => {
    imageUpload(uploadedimage, setImagePath, setisloading);
  }, [uploadedimage]);

  useEffect(() => {
    imageUpload(uploadedIdCard, setIdCardUrl, setisloading);
  }, [uploadedIdCard]);

  useEffect(() => {
    imageUpload(UploadedbirthCert, setBirthCertificateUrl, setisloading);
  }, [UploadedbirthCert]);

  useEffect(() => {
    imageUpload(uploadedUtils, setUtilityBillUrl, setisloading);
  }, [uploadedUtils]);

  useEffect(() => {
    imageUpload(uploadedSignature, setSignatureUrl, setisloading);
  }, [uploadedSignature]);
  useEffect(() => {
    setItems(theItem(steps));
    fetch();
  }, []);

  return (
    <Spin
      spinning={isloading}
      delay={500}
      size="large"
      tip={"Fetching Table Data...."}
      className=" text-[#9b7c27] font-bold"
    >
      <div className="lg:w-[70%] w-[90%]  mx-auto pt-20 pb-10">
        <Steps current={current} items={steps} />
        <div>{steps[current].content}</div>

        <div>
          {current > 0 && (
            <Button
              className="bg-[#9b7c27] text-white w-[30%] border my-5 "
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
        <Modal
          open={isModalOpen}
          centered={true}
          // okText="save"
          cancelText="Make Changes"
          // onOk={actionDeleteHandler}
          onCancel={OnCancelHandler}
          footer={null}
        >
          <div className=" w-[80%] mx-auto flex flex-col justify-center items-center text-center gap-5">
            <GrValidate className="text-[40px] text-[green] " />
            <p className="text-[18px] leading-[21px] flex flex-col font-bold gap-1 ">
              Details Successfully Submitted
            </p>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default IndividualAccount;
