import React from 'react'

const CurrencyVsPriceComponent = () => {
  return (
    <div className="border-2 flex flex-col border-[#b49132] gap-2 pb-2">
    <p className="bg-[#b49132] text-white text-[18px] py-[1px] px-2 leading-loose">
      Currencies VS USD (1$)
    </p>
    <div className="flex gap-1 flex-col px-2">
      <div className="flex justify-between items-center">
        <div className="flex_center gap-1">
          <img
            src="https://freecurrencyrates.com/flags/24/ng.png"
            alt=""
          />
          <p className="font-bold">NGN</p>
        </div>

        <div className="flex justify-start items-center border-2 px-1 border-[#b49132] text-[#b49132] w-[30%] font-bold">
          <p>₦</p>
          <p>768.84</p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex_center gap-1">
          <img
            src="https://freecurrencyrates.com/flags/24/eu.png"
            alt=""
          />
          <p className="font-bold uppercase">Eur</p>
        </div>

        <div className="flex justify-start items-center border-2 px-1 border-[#b49132] text-[#b49132] w-[30%] font-bold">
          <p>€</p>
          <p>0.92</p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex_center gap-1">
          <img
            src="https://freecurrencyrates.com/flags/24/gb.png"
            alt=""
          />
          <p className="font-bold">GBP</p>
        </div>

        <div className="flex justify-start items-center border-2 px-1 border-[#b49132] text-[#b49132] w-[30%] font-bold">
          <p>£</p>
          <p>0.79</p>
        </div>
      </div>

      {/* <div className="flex justify-between items-center">
        <div className="flex_center gap-1">
          <img
            src="https://freecurrencyrates.com/flags/24/jp.png"
            alt=""
          />
          <p className="font-bold">JPY</p>
        </div>

        <div className="flex justify-start items-center border-2 px-1 border-[#b49132] text-[#b49132] w-[30%] font-bold">
          <p>¥</p>
          <p>147.04</p>
        </div>
      </div> */}

      {/* <div className="flex justify-between items-center">
        <div className="flex_center gap-1">
          <img
            src="https://freecurrencyrates.com/flags/24/ng.png"
            alt=""
          />
          <p className="font-bold">CNY</p>
        </div>

        <div className="flex justify-start items-center border-2 px-1 border-[#b49132] text-[#b49132] w-[30%] font-bold">
          <p>¥</p>
          <p>7.30</p>
        </div>
      </div>*/}

      <p className="font-bold p-2">{Date()}</p>
    </div>
  </div>
  )
}

export default CurrencyVsPriceComponent
