import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import IndividualAccount from "./IndividualAccount";
// import EstateAccount from '../EstateAccount/EstateAccount';
// import CoporateAccount from "../AccountOpening/CoporateAccount";
import Offlineform from "../MediaPage/offlineform";
import Minor from "./minor";
import EstateAccount from "./EstateAccount";
import CoporateAccount from "./CoporateAccount";
import JointAccount from "./JointAccount";
// import EstateAccount from "../AccountOpening/EstateAccount";
const FormsPage = () => {
  const { id } = useParams();

  
  return id === "IndividualAccount" ? (
    <IndividualAccount />
  ) : id === "EstateAccount" ? (
    <EstateAccount />
  ) : id === "CorporateAccount" ? (
    <CoporateAccount />
  ) : id === "Minor" ? (
    <Minor />
  ) : id === "Joint" ? (
    <JointAccount />
  ) : (
    <Offlineform />
  );
};

export default FormsPage;
