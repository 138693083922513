import { Link } from "react-router-dom";
import firstIcon from "../../Assets/Icons/5.png";
import thirdIcon from "../../Assets/Icons/6.png";
import lastIcon from "../../Assets/Icons/3.png";
import bookmarkIcon from '../../Assets/Icons/13.png'
import { RiArrowRightDoubleLine } from "react-icons/ri";
const Financial_Planning = () => {
  return (
    <div className="w-full flex flex-col gap-8">
       <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Products We Offer
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1  justify-between items-center gap-10">

         

        <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={bookmarkIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/investment_advisory'>Investment Advisory</Link>
          </div>

        </div>
      </div>

      <div className="text-[18px] text-justify leading-loose text-[#424242] flex flex-col gap-8">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
        Financial Planning
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
      <p>
      Whether you want to invest on your own, have a windfall you want to invest or a financial challenge you’d like to solve, we would like to hear from you.
      </p>
        <p className="">
        You may want to plan for your children’s education or build your own home or maybe your debts and income are going in opposite directions. Our financial planning service is aimed at individuals who need professional advice in setting up:
        </p>

        <div className="pl-5 ">
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
           Savings plans for education and home building or development
          </p>
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
            Strategies to manage cash flow better and more efficiently
          </p>
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
            Getting advice and financial assistance on setting up your own business
          </p>
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
            An independent retirement plan
          </p>
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
            A plan to provide insurance protection for different human and real assets
          </p>
          <p className="flex justify-start items-center gap-1 ">
            {" "}
            <span className="text-[#b49132]">
              <RiArrowRightDoubleLine />
            </span>
            Creating an estate plan through a trust or foundation to ensure you leave a legacy but still have money for today
          </p>
         
        </div>
        <p>
        Whatever your plans or challenges are, our highly trained team of financial planners will help you draw up a tax efficient plan that gets you to your objective with the smallest degree of fuss – so you can enjoy living today while looking forward to tomorrow.
        </p>
      </div>

     
    </div>
  )
}

export default Financial_Planning
