import { Route, Routes } from "react-router-dom";
import AboutUsParentPage from "./AboutUsParentPage";
import OurCompany from "./OurCompany";
import WhoWeAre from "./WhoWeAre";
import MgtTeam from "./MgtTeam";
import BoardOfDirectors from "./BoardOfDirectors";
import NotFoundComponent from "../404Page/NotFoundPage";

const AboutUsHome = () => {
  return (
    <Routes>
      <Route path="/" element={<AboutUsParentPage />}>
        <Route index element={<WhoWeAre />} />
        <Route path="OurCompany" element={<OurCompany />} />
        <Route path="management_team" element={<MgtTeam/>} />
        <Route path="Boards" element={<BoardOfDirectors/>} />
        {/* <Route path="*" element={<NotFoundComponent />} /> */}
      </Route>
      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
};

export default AboutUsHome;
