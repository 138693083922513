import { Collapse, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import BoardCard from "../../components/cards/boardOfdirectors";
import { LoadingOutlined } from "@ant-design/icons";

const MgtTeam = () => {
  const [boards, setBoards] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getAllBoards();
      const filteredRes = res?.data.filter((value) => {
        return value.teamType === 'Teams' && value.isActive === true;
      });
      setBoards(filteredRes);
      setIsloading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);


  return (
    <div className="w-full">
      <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
        Management Team
        <span className="border-[1px] border-[#b49132] w-[5%]"></span>
      </p>

     <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
        <p>
          Our management team is made up of people who have distinguished
          themselves in various professions and fields. They include:
        </p>
        {/* <Collapse items={items} defaultActiveKey={["1"]} size="large" className="bg-[#c1c1c1] "/> */}
      </div>
      {
        isloading ? (
          <Spin
          indicator={<LoadingOutlined style={{ fontSize: 74 }} />}
          className="animate-ping   flex justify-center items-center h-full"
        />
        ) : (

<div>
          {error ? (
            <Result
              status="500"
              title="Refresh"
              subTitle="Sorry, something went wrong."
              // extra={<Button type="primary">Re</Button>}
            />
          ) : boards.length === 0 ? (
            <div className="h-[200px] justify-center items-center flex ">
              <p className="text-center capitalize text-lg font-bold ">
                No Management  record
              </p>
            </div>
          ) : (
            boards &&  boards.map((boardDetails, index) => (
              <BoardCard boardDetails={boardDetails} key={index} />
            ))
          )}
        </div>

        )
      }
      
    </div>
  );
};

export default MgtTeam;
