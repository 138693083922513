import React, { useEffect, useState } from "react";
import slide from '../../Assets/slides/slide-6.jpg'
import { Link } from "react-router-dom";

const ImageSlider = ({ slidesDetails }) => {
  
   const isImageValid = (imageUrl) => {
    return Boolean(
      imageUrl && typeof imageUrl === "string" && imageUrl.startsWith("http")
    );
  };
  return (
    <div className="md:h-screen h-[90vh]  ">
            <div
              className="capitalize h-full  flex justify-center  md:justify-start pt-[10%] items-center bg-center bg-cover bg-no-repeat flex-col text-white  "
              style={{ backgroundImage: `url(${isImageValid(slidesDetails?.imageUrl) ? slidesDetails?.imageUrl : slide})` }}
            >
              <div className="w-[95%] mx-auto  flex flex-col justify-center items-center text-center md:gap-2 gap-5 ">
                <p className="lg:text-[42px] text-[20px] capitalize lg:flex flex-col justify-center items-center leading-tight  ">
                  {slidesDetails?.headings1}
                 
                </p>
                <p className="text-[24px] break-all lg:text-[39px] font-bold uppercase ">{slidesDetails?.headings2}</p>{" "}
                <p className="text-[20px] text-center md:flex flex-col justify-center items-center font-[200] w-[90%] md:w-[70%] ">
                 {slidesDetails?.content}
                </p>
                <Link to={'/AboutUs/'} >
                <button className="bg-[#B49132] hover:bg-[#9b7c27] rounded-lg uppercase py-2 px-5 font-bold mt-8 text-[16px]">
                  Learn More
                </button>
                </Link>
              </div>
            </div>
          </div>
  );
};

export default ImageSlider;
