
import thirdIcon from "../../Assets/Icons/6.png";
import lastIcon from "../../Assets/Icons/3.png";
import { Link } from "react-router-dom";

const InvestmentAdvisoryPage = () => {
  return (
    <div className="w-full text-[18px] text-justify leading-loose text-[#424242] flex flex-col gap-5">
      <div className="flex flex-col gap-5">
        <p className="flex justify-center items-start flex-col text-start  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
          Customized Stock Recommedations
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          We render customized stock recommendations to our clients with the aim
          of achieving superior returns. The investment objectives, risk profile
          and portfolio composition/asset allocation mix are embedded in our
          recommendations.
        </p>
      </div>

      <div className="flex flex-col gap-5">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
          Portfolio Reconstruction
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          Portfolio reconstruction refers to the process of turning around a
          portfolio that is in a delinquent state. We develop portfolio
          reconstruction and performance enhancing strategies of our clientele
          to assist them discover and recover bad investment in their portfolio.
        </p>
        <p>
          The opportunity cost of holding on to non-performing and bad
          investments is considered. Portfolio reconstruction involves selling
          down on the stocks in bad state in terms of returns and value
          maintenance. The proceed will then be applied to buy fundamentally
          sound stocks that may improve the portfolio structure and value.
        </p>
      </div>

      <div className="flex flex-col gap-5">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
          Buying In
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          We promote the benefit of investing in stocks and arrange convenient
          ways for clients to invest and achieve exceptional result by trading –
          buying shares when prices are low and disposing them at higher prices
          bearing in mind the exit strategies and mix portfolio.
        </p>
      </div>

      <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col text-[32px] leading-loose  font-bold text-[#373c5f] capitalize">
          Other Services
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1  justify-between items-center gap-10">
          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
              <img src={thirdIcon} alt="" />
            </div>

            <Link className="flex justify-center items-center gap-1" to="/Products/Brokerage_Dealing"> Brokerage & Dealing</Link>
          </div>

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
              <img src={lastIcon} alt="" />
            </div>

            <Link className="flex justify-center items-center gap-1" to="/Products/fixed_income_Investment">
              Fixed Income Investment
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentAdvisoryPage;
