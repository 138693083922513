import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductPage from "./ProductPage";
import ProductParentPage from "./ProductParentPage";
import PortfolioMgt from "./PortfolioMgt";
import AlternativeInvestment from "./Alternative_Investment";
import Financial_Planning from "./Financial_Planning";
import StokingBroking from "./Stoking_Broking";
import InvestmentAdvisoryPage from "../ServicesPage/Investment_advisoryPage";
import FixedIncomePage from "../ServicesPage/FixedIncomePage";
import BrokerageDealing from "../ServicesPage/Brokerage_Dealing";

const ProductHomePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductParentPage />}>
        <Route index element={<ProductPage />} />
        <Route path="financial-Investments" element={<Financial_Planning/>} />
        <Route path="Stoking-broking" element={<StokingBroking/>} />
        <Route
          path="investment_advisory"
          element={<InvestmentAdvisoryPage />}
        />
        <Route path="fixed_income_Investment" element={<FixedIncomePage />} />
        <Route path="Brokerage_Dealing" element={<BrokerageDealing />} />
      </Route>
    </Routes>
  );
};

export default ProductHomePage;
