import bookmarkIcon from '../../Assets/Icons/13.png'
import thirdIcon from '../../Assets/Icons/6.png'
import { Link } from 'react-router-dom'

const FixedIncomePage = () => {
  return (
    <div className="w-full text-[18px] text-justify leading-loose text-[#424242] flex flex-col gap-5">
     <div className="flex flex-col gap-5">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
        Fixed Income Investment
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
        Fixed Income Investment Services through our Heritage income product, Heritage managed account which offers a competitive rate above money market instrument.
        </p>
      </div>
      <div className="flex flex-col gap-5">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
        Heritage Proprietary Trading
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
        We are very creative in the management of our proprietary and client portfolio management. A scheme that clearly distinguishes our skills in superior value proposition to our clients.
        </p>
        <p>
        We are currently pursuing registration for specialist functions of Issuing House and Market Making. Our Products integrate the investors objectives and Risk tolerance level.
        </p>
        <p>

        </p>
      </div>

      <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col text-[32px] leading-loose  font-bold text-[#373c5f] capitalize">
          Other Services
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1  justify-between items-center gap-10">

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={bookmarkIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/investment_advisory'>Investment Advisory</Link>
          </div>

         
          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={thirdIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/Brokerage_Dealing'>	Brokerage & Dealing</Link>
          </div>

          

        </div>
      </div>
    </div>
  )
}

export default FixedIncomePage
