import React from 'react'
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFoundComponent = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="default" onClick={() => navigate("/")}>
          Back Home
        </Button>
      }
    />
  )
}

export default NotFoundComponent
