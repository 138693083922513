import {RiArrowRightDoubleLine} from 'react-icons/ri'
import QuoteComponent from '../../components/QuoteComponent/QuoteComponent'

const OurCompany = () => {
  return (
    <div className=" w-full ">
    <div className="flex flex-col gap-10 ">
      <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
      History
        <span className="border-[1px] border-[#b49132] w-[5%]"></span>
      </p>

      <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
        <p>
        Heritage Capital Markets Limited is licensed by The Nigerian Stock Exchange, Securities & Exchange Commission and FMDQ to operate as broker/dealer in the Capital Market and to offer brokerage services in fixed income securities.
        </p>

        <p>
        The Company was incorporated on 24th November 1997 and now has an authorized share capital of ₦2,000,000,000 in shares of ₦1.00 each and shareholders’ fund in excess of ₦1,000,000,000. Our current share capital position enables us to position for the emerging investment banking opportunities in our environment.
        </p>
        <p>
        Over the years, we have evolved to become a strong and veritable player in the Capital Market segment of the Nigerian economy successfully weathering the crisis in the sector.
        </p>

      </div>


     
    </div>
    
  </div>
  )
}

export default OurCompany
