import { Route, Routes } from "react-router-dom";
import ServicesParentPage from "./ServicesParentPage";
import WhatWeDoPage from "./What-We-Do";
import InvestmentAdvisoryPage from "./Investment_advisoryPage";
import FixedIncomePage from "./FixedIncomePage";
import BrokerageDealing from "./Brokerage_Dealing";

const ServicesHomePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ServicesParentPage />}>
        <Route index element={<WhatWeDoPage />} />
        <Route
          path="investment_advisory"
          element={<InvestmentAdvisoryPage />}
        />
        <Route path="fixed_income_Investment" element={<FixedIncomePage />} />
        <Route path="Brokerage_Dealing" element={<BrokerageDealing />} />
      </Route>
    </Routes>
  );
};

export default ServicesHomePage;
