
import bookmarkIcon from '../../Assets/Icons/13.png'
import thirdIcon from '../../Assets/Icons/6.png'
import lastIcon from '../../Assets/Icons/3.png'
import { Link } from 'react-router-dom'

const WhatWeDoPage = () => {
  return (
    <div className='w-full text-[18px] text-justify leading-loose text-[#424242] flex flex-col gap-5'>
      <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize pb-5">
      What We Do
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
        You see an opportunity and we help you get to it. We see it, and we make sure you don’t lose it. We know that there is a big difference to seeing opportunity and capturing it. This is why we have implemented a best execution strategy that ensures that we will attempt to get you an even better price on the trade where possible.
        </p>
        <p>
        For clients who want us to simply execute a trade we will give you insights into the market through our technical analysis and fundamental research. Our daily market snapshots will help you know when your targets are within the take action zone.
        </p>

        <p>
        For clients who want us to manage their trading portfolio we will endeavor to understand your return and time objectives and create a strategy which we will explain and execute on your behalf. Our strategy will capture profits in line with your needs and either execute or transfer to short term money instruments.
        </p>
        <p>
        Large clients want their trades not to leave a foot print – ensuring the last price is as close to the first price as much as possible. We will put in place measures to help you achieve it. So if you are looking for speed, liquidity or transparency we will help you meet your goals.
        </p>

        <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col text-[32px] leading-loose  font-bold text-[#373c5f] capitalize">
          Other Services
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1  justify-between items-center gap-10">

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={bookmarkIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/investment_advisory'>Investment Advisory</Link>
          </div>

         
          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={thirdIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/Brokerage_Dealing'>	Brokerage & Dealing</Link>
          </div>

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={lastIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/fixed_income_Investment'>Fixed Income Investment</Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default WhatWeDoPage
