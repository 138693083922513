import { ImRedo2 } from "react-icons/im";

const MarketResearchPage = () => {
  return (
    <div className="w-full text-[18px] text-justify leading-loose text-[#424242]">
      <div className="flex flex-col gap-5 ">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] pb-3">
          Research
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          You can search for current stocks by typing the codename in the search
          box provided. You could also extend your search by adding dates to the
          search term. For example searching "ZENITH" will return stock price
          for ZENITHBANK for today.We've taken the guess work out of investment.
          We don't do investment on rumour or hunches; we follow a strict rules
          based approach to investment. Our in house investment universe only
          contains stocks that meet our stringent qualifying standards of
          liquidity, historic growth, income and value.
        </p>
        <p>
          We offer our research free to all our clients irrespective of their
          size and we offer it using a variety of media; Ms Powerpoint, PDF,
          audio and even video clips; whatever your investment preference or
          learning style we have research that helps you discover tomorrow
          today.
        </p>
      </div>

      <div className="flex flex-col gap-5 ">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] pb-3">
          Company Financial Analysis
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          Click on each company to learn more about their financial records,
          analysis and performance in the current market.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div className="  border border-[#1F2134] ">
            <p className=" p-0 m-0 flex justify-start gap-2 items-center bg-[#1F2134] text-white">
              {" "}
              <span>
                <ImRedo2 className="text-[30px] text-white" />
              </span>
              Academy Press
            </p>

            <div className="  w-[95%] mx-auto  pt-5">
              <table className="divide-y  divide-gray-300 !w-full  ">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className=" py-2 px-2 text-xs text-gray-500 capitalize">
                      company
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500 uppercase">
                      Academy
                    </td>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-300">
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      NSE Classification
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      Printing/Publishing
                    </td>
                  </tr>
                  <tr className=" bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">Share Price</th>
                    <td className=" py-2 px-2 text-xs text-gray-500">0.91</td>
                  </tr>
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Share Capital
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">604,800,000</td>
                  </tr>
                  <tr className="bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Market Capitalization
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      ₦550,368,000.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-end mr-1 mt-6 mb-1">
            <button className="uppercase px-4   py-1 bg-[#b49132] text-white font-[600] border text-[12px] rounded hover:bg-[#9c823a]">
                learn more
            </button>
            </div>
           
          </div>

          <div className="  border border-[#1F2134] ">
            <p className=" p-0 m-0 flex justify-start gap-2 items-center bg-[#1F2134] text-white">
              {" "}
              <span>
                <ImRedo2 className="text-[30px] text-white" />
              </span>
              Academy Press
            </p>

            <div className="  w-[95%] mx-auto  pt-5">
              <table className="divide-y  divide-gray-300 !w-full  ">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className=" py-2 px-2 text-xs text-gray-500 capitalize">
                      company
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500 uppercase">
                      Academy
                    </td>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-300">
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      NSE Classification
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      Printing/Publishing
                    </td>
                  </tr>
                  <tr className=" bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">Share Price</th>
                    <td className=" py-2 px-2 text-xs text-gray-500">0.91</td>
                  </tr>
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Share Capital
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">604,800,000</td>
                  </tr>
                  <tr className="bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Market Capitalization
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      ₦550,368,000.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-end mr-1 mt-6 mb-1">
            <button className="uppercase px-4   py-1 bg-[#b49132] text-white font-[600] border text-[12px] rounded hover:bg-[#9c823a]">
                learn more
            </button>
            </div>
           
          </div>

          <div className="  border border-[#1F2134] ">
            <p className=" p-0 m-0 flex justify-start gap-2 items-center bg-[#1F2134] text-white">
              {" "}
              <span>
                <ImRedo2 className="text-[30px] text-white" />
              </span>
              Academy Press
            </p>

            <div className="  w-[95%] mx-auto  pt-5">
              <table className="divide-y  divide-gray-300 !w-full  ">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className=" py-2 px-2 text-xs text-gray-500 capitalize">
                      company
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500 uppercase">
                      Academy
                    </td>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-300">
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      NSE Classification
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      Printing/Publishing
                    </td>
                  </tr>
                  <tr className=" bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">Share Price</th>
                    <td className=" py-2 px-2 text-xs text-gray-500">0.91</td>
                  </tr>
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Share Capital
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">604,800,000</td>
                  </tr>
                  <tr className="bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Market Capitalization
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      ₦550,368,000.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-end mr-1 mt-6 mb-1">
            <button className="uppercase px-4   py-1 bg-[#b49132] text-white font-[600] border text-[12px] rounded hover:bg-[#9c823a]">
                learn more
            </button>
            </div>
           
          </div>

          <div className="  border border-[#1F2134] ">
            <p className=" p-0 m-0 flex justify-start gap-2 items-center bg-[#1F2134] text-white">
              {" "}
              <span>
                <ImRedo2 className="text-[30px] text-white" />
              </span>
              Academy Press
            </p>

            <div className="  w-[95%] mx-auto  pt-5">
              <table className="divide-y  divide-gray-300 !w-full  ">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className=" py-2 px-2 text-xs text-gray-500 capitalize">
                      company
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500 uppercase">
                      Academy
                    </td>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-300">
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      NSE Classification
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      Printing/Publishing
                    </td>
                  </tr>
                  <tr className=" bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">Share Price</th>
                    <td className=" py-2 px-2 text-xs text-gray-500">0.91</td>
                  </tr>
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Share Capital
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">604,800,000</td>
                  </tr>
                  <tr className="bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Market Capitalization
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      ₦550,368,000.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-end mr-1 mt-6 mb-1">
            <button className="uppercase px-4   py-1 bg-[#b49132] text-white font-[600] border text-[12px] rounded hover:bg-[#9c823a]">
                learn more
            </button>
            </div>
           
          </div>

          <div className="  border border-[#1F2134] ">
            <p className=" p-0 m-0 flex justify-start gap-2 items-center bg-[#1F2134] text-white">
              {" "}
              <span>
                <ImRedo2 className="text-[30px] text-white" />
              </span>
              Academy Press
            </p>

            <div className="  w-[95%] mx-auto  pt-5">
              <table className="divide-y  divide-gray-300 !w-full  ">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className=" py-2 px-2 text-xs text-gray-500 capitalize">
                      company
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500 uppercase">
                      Academy
                    </td>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-300">
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      NSE Classification
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      Printing/Publishing
                    </td>
                  </tr>
                  <tr className=" bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">Share Price</th>
                    <td className=" py-2 px-2 text-xs text-gray-500">0.91</td>
                  </tr>
                  <tr className=" bg-white">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Share Capital
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">604,800,000</td>
                  </tr>
                  <tr className="bg-gray-200">
                    <th className=" py-2 px-2 text-xs text-gray-500">
                      Market Capitalization
                    </th>
                    <td className=" py-2 px-2 text-xs text-gray-500">
                      ₦550,368,000.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-end mr-1 mt-6 mb-1">
            <button className="uppercase px-4   py-1 bg-[#b49132] text-white font-[600] border text-[12px] rounded hover:bg-[#9c823a]">
                learn more
            </button>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketResearchPage;
