import React from "react";
import {
  Form,
  Input,
  Button,
  Steps,
  DatePicker,
  Upload,
  Spin,
  Select,
  Checkbox,
  Modal,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useState } from "react";
import {
  currentDateTime,
  imageUpload,
  onFinishHandlers,
} from "../../helpers/helpers";
import { UploadOutlined } from "@ant-design/icons";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import { Link } from "react-router-dom";
import { GrValidate } from "react-icons/gr";
import { CiCircleCheck } from "react-icons/ci";
const {Option} = Select
const CoporateAccount = () => {
  const [current, setCurrent] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [AllBanks, setAllBanks] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [uploadedimage, setUploadedImage] = useState(null);
  const [passportPath, setPassportPath] = useState("");
  const [isloading, setisloading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [allState, setallState] = useState([]);
  const [uploadedCACcert, setuploadedCACcert] = useState(null);
  const [uploadCACcertPath, setUploadCACcertPath] = useState("");
  const [personalDetailsId, setpersonalDetailsId] = useState(0);
  const [uploadedSignature, setuploadedSignature] = useState(null);
  const [signatureUrl, setSignatureUrl] = useState("");

  const [uploadedCac02, setuploadedCac02] = useState(null);
  const [uploadedCac02path, setuploadedCac02path] = useState("");

  const [uploadedCac07, setuploadedCac07] = useState(null);
  const [uploadedCac07path, setuploadedCac07path] = useState("");

  const [idCardOfSignatory, setidCardOfSignatory] = useState(null);
  const [idCardOfSignatorypath, setidCardOfSignatorypath] = useState("");
  const [idCardOfSignatory2, setidCardOfSignatory2] = useState(null);
  const [idCardOfSignatory2path, setidCardOfSignatory2path] = useState("");

  const [boardSignatory1, setboardSignatory1] = useState(null);
  const [boardSignatory1path, setboardSignatory1path] = useState("");

  const [boardSignatory2, setboardSignatory2] = useState(null);
  const [boardSignatory2path, setboardSignatory2path] = useState("");

  const [boardSignatory3, setboardSignatory3] = useState(null);
  const [boardSignatory3path, setboardSignatory3path] = useState("");

  const [companyResolution, setcompanyResolution] = useState(null);
  const [companyResolutionpath, setcompanyResolutionpath] = useState("");

  const [namingResolution, setnamingResolution] = useState(null);
  const [namingResolutionpath, setnamingResolutionpath] = useState("");

  const [utilBill, setutilBill] = useState(null);
  const [utilBillpath, setutilBillpath] = useState("");

  const [signatoriespassport, setsignatoriespassport] = useState(null);
  const [signatoriespassportpath, setsignatoriespassportpath] = useState("");

  const [signatoriespassport2, setsignatoriespassport2] = useState(null);
  const [signatoriespassport2path, setsignatoriespassport2path] = useState("");

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const initialValues = {
    estateName: "",
    dateOfBirthDeceased: "",
    mothersMaidenName: "",
    address: "",
    armor1Name: "",
    armor1Address: "",
    passportLGA: "",
    city: "",
    state: "",
    country: "",
    mobileNo: "",
    email: "",
    signatories: "",
    nextOfKinName: "",
    nextOfKinRelationship: "",
    nextOfKinAddress: "",
    nextOfKinTelNo: "",
    nextOfKinEmail: "",
    signatureMandate: "",
    bankName: "",
    bankBranch: "",
    bankAccountName: "",
    bankAccountNumber: "",
    dateAccountOpened: "",
    typeOfAccount: "",
    bvn: "",
  };
  const OnCancelHandler = () => {
    setIsModalOpen(false);
    form.resetFields();
    setCurrent(0);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const companyReg = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      delete values?.photo1;
      delete values?.photo2;
      delete values?.photo3;
      delete values?.photo5;
      delete values?.photo6;
      delete values?.photo7;
      delete values?.photo8;
      delete values?.photo9;
      delete values?.photo10;
      delete values?.photo11;
      delete values?.photo12;
      delete values?.photo13;
      try {
        const res = await adminServices.companyReg({
          ...values,
          certificateOfIncorporation: uploadCACcertPath,

          dateCreated: currentDateTime,
          caC02: uploadedCac02path,
          caC07: uploadedCac07path,
          idCardOfSignatory: idCardOfSignatorypath,
          idCardOfSignatory2: idCardOfSignatory2path,
          boardSignature1: boardSignatory1path,
          boardSignature2: boardSignatory2path,
          boardSignature3: boardSignatory3path,
          companyResolution: companyResolutionpath,
          namingResolution: namingResolutionpath,
          utilityBill: utilBillpath,
          signatoryPassport: signatoriespassportpath,
          signatory2Passport: signatoriespassport2path,
        });
        setCompanyId(res?.data?.id);
        setisloading(false);
        setCurrent(current + 1);
      } catch (error) {
        setisloading(false);
        NotificationService.show(error.message, "error");
      }
    }
  };
  const directorReg = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      try {
        const res = await adminServices.personalDetails({
          formTypeId: 3,
          firstName: '',
          surname: values?.surname,
          middleName: '',
          maidenName: '',
          email: values?.email,
          phoneNumber: values?.phoneNumber,
          dateofBirth: values?.dateofBirth,
          genderId: values?.genderId,
          countryId: values?.countryId,
          stateId: values?.stateId,
          passport: passportPath,
          city: values?.city,
          address: values?.address,
          idCardType: '',
          idCardNumber: '',
          birthCertificateUrl: '',
          idCardUrl: '',
          utilityBillUrl: '',
          signatureUrl: signatureUrl,
          idCardExpiryDate: currentDateTime,
          companyId: companyId,
        });
        if(res?.data){
          setpersonalDetailsId(res?.data)
          setisloading(true);
          const kycResponse = await adminServices.PostKycDetails({
            name: values?.nextOfKinName,
            relationship: values?.nextOfKinRelationship,
            occupation: '',
            phoneNumber: values?.nextOfKinTelNo,
            address: values?.nextOfKinAddress,
            personalDetailsId: res?.data,
          });
          if(kycResponse?.data){
            form.resetFields();
            setCurrent(current+1)
          }
        }
        form.resetFields();
setisloading(false)
      } catch (error) {
        NotificationService.show(error.message, "error");
      }
     
      
    }
  };

  const onFinishBankDetails = async () => {
    if (await form.validateFields({ validateOnly: true })) {
      setisloading(true);
      delete values.terms;
      try {
        const res = await adminServices.PostBankDetails({
          ...values,
          personalDetailsId: personalDetailsId,
        });
        setisloading(false);
        setIsModalOpen(true);
      } catch (error) {
        setisloading(false);
      }
    }
  };
  const fetch = async () => {
    setisloading(true);
    try {
      const res = await adminServices.getAllCountry();
      const stateRes = await adminServices.getAllState();
      const bankRes = await adminServices.getAllBanks();
      setAllCountry(res.data);
      setallState(stateRes.data);
      setAllBanks(bankRes.data);
      setisloading(false);
    } catch (error) {
      setisloading(false);
      NotificationService.show(error.message, "error");
    }
  };
  const steps = [
    {
      title: "Company Details",
      content: (
        <>
          <Form
            form={form}
            layout="vertical"
            className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
            onFinish={() => companyReg()}
            initialValues={initialValues}
          >
            <Form.Item
              label="company  Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the company  Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="flex gap-3 flex-wrap md:flex-row flex-col">
              <Form.Item
                label="RC NO"
                name="registration"
                rules={[
                  {
                    required: true,
                    message: "Please enter the RC NO",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Date of Registration"
                name="dateOfRegistration"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Date of Registration",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </div>

            <Form.Item
              label="Business Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter the Business Address",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">All CAC Documents</p>
              <div className="grid lg:grid-cols-3 grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[
                    { required: true, message: "Certificate of Incorporation" },
                  ]}
                  className="mb-1 flex-1"
                  name="photo1"
                  label="Certificate of Incorporation"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setuploadedCACcert)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "CAC02" }]}
                  className="mb-1 flex-1"
                  name="photo2"
                  label="CAC02"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setuploadedCac02)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "CAC07" }]}
                  className="mb-1 flex-1"
                  name="photo3"
                  label="CAC07"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setuploadedCac07)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">
                ID Cards of the Signatories to the Account
              </p>
              <div className="grid lg:grid-cols-2 grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[{ required: true, message: "ID Card Of Signatory" }]}
                  className="mb-1 flex-1"
                  name="photo5"
                  label="ID Card Of Signatory"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setidCardOfSignatory)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "ID Card Of Signatory2" }]}
                  className="mb-1 flex-1"
                  name="photo6"
                  label="ID Card Of Signatory2"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setidCardOfSignatory2)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">
                Board of Directors signature mandate
              </p>
              <div className="grid lg:grid-cols-3 grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[{ required: true, message: "board Signature " }]}
                  className="mb-1 flex-1"
                  name="photo7"
                  label="board Signature "
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setboardSignatory1)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "board Signature 2" }]}
                  className="mb-1 flex-1"
                  name="photo8"
                  label="board Signature 2"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setboardSignatory2)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: "board Signature 3" }]}
                  className="mb-1 flex-1"
                  name="photo9"
                  label="board Signature 3"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setboardSignatory3)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">
                Resolution from the Company authorizing the Account creation
              </p>
              <div className="grid grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[{ required: true, message: "Resolution" }]}
                  className="mb-1 flex-1"
                  name="photo10"
                  label="Resolution"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setcompanyResolution)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">
                Resolution naming the signatories as the people to sign on the
                accounts
              </p>
              <div className="grid lg:grid-cols-3 grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[{ required: true, message: "Resolution" }]}
                  className="mb-1 flex-1"
                  name="photo11"
                  label="Resolution2"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setnamingResolution)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start gap-5 ">
              <p className="text-[#6d1b1b] pt-5 font-bold">
                Recent passport photographs each of the signatories.
              </p>
              <div className="grid lg:grid-cols-2 grid-cols-1 justify-between items-center w-full">
                <Form.Item
                  rules={[{ required: true, message: "Signatories 1" }]}
                  className="mb-1 flex-1"
                  name="photo12"
                  label="Signatories 1"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setsignatoriespassport)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: "Signatories 2" }]}
                  className="mb-1 flex-1"
                  name="photo12"
                  label="Signatories 2"
                >
                  <Upload
                    onChange={(file) =>
                      handleImageUpload(file, setsignatoriespassport2)
                    }
                    customRequest={dummyRequest}
                    maxCount={1}
                    // fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <Form.Item
              rules={[{ required: true, message: "Utility Bill" }]}
              className="mb-1 flex-1"
              name="photo13"
              label="Utility Bill"
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setutilBill)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
              <Button
                htmlType="submit"
                className="bg-[#001529] text-white w-[30%] border my-5 "
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
      icon:
        current > 0 ? (
          <CiCircleCheck />
        ) : (
          <div className="border border-[#001529] p-[2px] !rounded-[100%]">
            <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
              1
            </p>
          </div>
        ),
    },
    {
      title: "Director 1",
      content: (
        <>
          <Form
            form={form}
            layout="vertical"
            className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
            onFinish={() => directorReg()}
            initialValues={initialValues}
          >
            <div className="flex justify-between items-center gap-10">
            <Form.Item
              label="Full Name"
              className="flex-1"
              name="surname"
              rules={[
                {
                  required: true,
                  message: "Please enter the Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter Date Of Birth" }]}
              className=" flex-1 text-black"
              name="dateofBirth"
              label="Date of Birth"
            >
              <DatePicker style={{ width: "100%" }} className="text-black" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Select Gender" }]}
              className="flex-1"
              name="genderId"
              label="Gender"
            >
              <Select placeholder="Select Gender">
                <Option value={1}>Male</Option>
                <Option value={2}>Female</Option>
              </Select>
            </Form.Item>
            </div>
           

            <Form.Item
              label="Residential Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter the Residential Address",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please enter the City",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Select State" }]}
                className="mb-1 flex-1"
                name="stateId"
                label="State of Origin"
              >
                <Select
                  allowClear
                  optionFilterProp="children"
                  placeholder="State of Origin"
                  className="text-gray-600"
                >
                  {allState.map(({ id, name }, index) => (
                    <Select.Option value={id} key={index}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Select Country" }]}
                className="mb-1 flex-1"
                name="countryId"
                label="Nationality"
              >
                <Select
                  allowClear
                  optionFilterProp="children"
                  placeholder="Nationality"
                  className="text-gray-600"
                >
                  {allCountry.map(({ id, name }, index) => (
                    <Select.Option value={id} key={index}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                rules={[{ required: true, message: "Enter phone number" }]}
                className="mb-1 flex-1"
                name="phoneNumber"
                label="Moile No."
              >
                <Input placeholder="Telephone" type="number" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Email" }]}
                className="mb-1 flex-1"
                name="email"
                label="E-mail address"
              >
                <Input placeholder="E-mail address" type="email" />
              </Form.Item>
            </div>

            <Form.Item
              rules={[{ required: true, message: "upload passport" }]}
              className="mb-1 flex-1"
              name="photo55"
              label="Passport Photograph"
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setUploadedImage)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload Photo</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Upload your signature" }]}
              className="mb-1 flex-1"
              name="photo8"
              label="Upload your signature"
            >
              <Upload
                onChange={(file) =>
                  handleImageUpload(file, setuploadedSignature)
                }
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload your signature</Button>
              </Upload>
            </Form.Item>
            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="next Of Kin Name"
                name="nextOfKinName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Name",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Relationship"
                name="nextOfKinRelationship"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Relationship",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              label="Next of Kin Address"
              name="nextOfKinAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter the Next of Kin Address",
                },
              ]}
              className="mb-1 flex-1"
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="Next of Kin Tel No"
                name="nextOfKinTelNo"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Next of Kin Tel No",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Next of Kin Email"
                name="nextOfKinEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Next of Kin Email",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
              <Button
                htmlType="submit"
                className="bg-[#001529] text-white w-[30%] border my-5 "
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
      icon:
        current > 1 ? (
          <CiCircleCheck />
        ) : (
          <div className="border border-[#001529] p-[2px] !rounded-[100%]">
            <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
              2
            </p>
          </div>
        ),
    },
    {
      title: "Director 2",
      content: (
        <>
          <Form
            form={form}
            layout="vertical"
            className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
            onFinish={() => directorReg()}
            initialValues={initialValues}
          >
            <div className="flex justify-between items-center gap-10">
            <Form.Item
              label="Full Name"
              className="flex-1"
              name="surname"
              rules={[
                {
                  required: true,
                  message: "Please enter the Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Enter Date Of Birth" }]}
              className=" flex-1 text-black"
              name="dateofBirth"
              label="Date of Birth"
            >
              <DatePicker style={{ width: "100%" }} className="text-black" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Select Gender" }]}
              className="flex-1"
              name="genderId"
              label="Gender"
            >
              <Select placeholder="Select Gender">
                <Option value={1}>Male</Option>
                <Option value={2}>Female</Option>
              </Select>
            </Form.Item>
            </div>
           

            <Form.Item
              label="Residential Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter the Residential Address",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please enter the City",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Select State" }]}
                className="mb-1 flex-1"
                name="stateId"
                label="State of Origin"
              >
                <Select
                  allowClear
                  optionFilterProp="children"
                  placeholder="State of Origin"
                  className="text-gray-600"
                >
                  {allState.map(({ id, name }, index) => (
                    <Select.Option value={id} key={index}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Select Country" }]}
                className="mb-1 flex-1"
                name="countryId"
                label="Nationality"
              >
                <Select
                  allowClear
                  optionFilterProp="children"
                  placeholder="Nationality"
                  className="text-gray-600"
                >
                  {allCountry.map(({ id, name }, index) => (
                    <Select.Option value={id} key={index}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                rules={[{ required: true, message: "Enter phone number" }]}
                className="mb-1 flex-1"
                name="phoneNumber"
                label="Moile No."
              >
                <Input placeholder="Telephone" type="number" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Email" }]}
                className="mb-1 flex-1"
                name="email"
                label="E-mail address"
              >
                <Input placeholder="E-mail address" type="email" />
              </Form.Item>
            </div>

            <Form.Item
              rules={[{ required: true, message: "upload passport" }]}
              className="mb-1 flex-1"
              name="photo55"
              label="Passport Photograph"
            >
              <Upload
                onChange={(file) => handleImageUpload(file, setUploadedImage)}
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload Photo</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Upload your signature" }]}
              className="mb-1 flex-1"
              name="photo8"
              label="Upload your signature"
            >
              <Upload
                onChange={(file) =>
                  handleImageUpload(file, setuploadedSignature)
                }
                customRequest={dummyRequest}
                maxCount={1}
                // fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload your signature</Button>
              </Upload>
            </Form.Item>
            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="next Of Kin Name"
                name="nextOfKinName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Name",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Relationship"
                name="nextOfKinRelationship"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Relationship",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              label="Next of Kin Address"
              name="nextOfKinAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter the Next of Kin Address",
                },
              ]}
              className="mb-1 flex-1"
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <div className="flex flex-col md:flex-row gap-3">
              <Form.Item
                label="Next of Kin Tel No"
                name="nextOfKinTelNo"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Next of Kin Tel No",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Next of Kin Email"
                name="nextOfKinEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Next of Kin Email",
                  },
                ]}
                className="mb-1 flex-1"
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
              <Button
                htmlType="submit"
                className="bg-[#001529] text-white w-[30%] border my-5 "
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
      icon:
        current > 2 ? (
          <CiCircleCheck />
        ) : (
          <div className="border border-[#001529] p-[2px] !rounded-[100%]">
            <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
              3
            </p>
          </div>
        ),
    },
    {
      title: "Bank Details",
      content: (
        <>
          <Form
            form={form}
            // initialValues={initialValues}
            onFinish={() => onFinishBankDetails()}
            layout="vertical"
            className="bg-gray-50 lg:py-10 lg:px-8 px-4  rounded flex flex-col gap-3 shadow-2xl"
          >
            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "CHN NO" }]}
                className="mb-1 flex-1"
                name="chn"
                label="CHN NO"
              >
                <Input placeholder="CHN NO" type="number" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter BVN NO" }]}
                className="mb-1 flex-1"
                name="bvn"
                label="BVN NO"
              >
                <Input placeholder="BVN NO" type="number" />
              </Form.Item>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter Name of Bank" }]}
                className="mb-1 flex-1"
                name="bankId"
                label="Name of Bank"
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Banks"
                  className="text-gray-600"
                >
                  {AllBanks.map(({ id, bankName }, index) => (
                    <Select.Option value={id} key={index}>
                      {bankName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter Bank Account No" }]}
                className="mb-1 flex-1"
                name="accountNumber"
                label="Bank Account No."
              >
                <Input placeholder="Bank Account No." type="number" />
              </Form.Item>
            </div>
            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter Bank Account Name" }]}
                className="mb-1 flex-1"
                name="accountName"
                label="Bank Account Name"
              >
                <Input placeholder="Bank Account Name" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Enter Date Of Birth" }]}
                className="mb-1 flex-1"
                name="accountType"
                label="A/C Type"
              >
                <Input placeholder="savings" />
              </Form.Item>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[
                  { required: true, message: "Enter Date of Account Created" },
                ]}
                className="mb-1 flex-1"
                name="dateOfCreation"
                label="Date of Account Created"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Bank Short Code" }]}
                className="mb-1 flex-1"
                name="bankShortCode"
                label="Bank Short Code"
              >
                <Input placeholder="Bank Short Code" />
              </Form.Item>
            </div>
            <Form.Item
              rules={[{ required: true, message: "bank branch address" }]}
              className="mb-1 flex-1"
              name="branchAddress"
              label="bank branch address"
            >
              <Input.TextArea placeholder=" Address" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Terms and Condition" }]}
              className="mb-1 flex-1"
              name="terms"
              label=""
            >
              <div className="flex gap-3">
                <Checkbox />
                <p>
                  I agree with the{" "}
                  <span>
                    <Link to={"/Terms&Condition"}>Terms and Conditions</Link>{" "}
                  </span>
                </p>
              </div>
            </Form.Item>
            {/* <Form.Item rules={[{ required: true }]} className="mb-1 flex-1"> */}
            <Button
              className="bg-[#001529] text-white w-[30%] border my-5 "
              htmlType="submit"
            >
              Submit
            </Button>
            {/* </Form.Item> */}
          </Form>
        </>
      ),
      icon:
        current > 3 ? (
          <CiCircleCheck />
        ) : (
          <div className="border border-[#001529] p-[2px] !rounded-[100%]">
            <p className="text-[13px] font-[500] text-center bg-[#001529] md:px-3 px-1  text-[white]  !rounded-[100%]">
              4
            </p>
          </div>
        ),
    },
  ];

  const theItem = (arr) =>
    arr.map((item) => ({
      key: item.title,
      title: item.title,
    }));

  useEffect(() => {
    fetch()
    setItems(theItem(steps));
  }, []);

  const handleImageUpload = (info, setUploadedImage) => {
  //console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
      // console.log(file);
      setUploadedImage(file);
    }
  };

  useEffect(() => {
    imageUpload(uploadedCACcert, setUploadCACcertPath, setisloading);
  }, [uploadedCACcert]);

  useEffect(() => {
    imageUpload(uploadedCac02, setuploadedCac02path, setisloading);
  }, [uploadedCac02]);

  useEffect(() => {
    imageUpload(uploadedCac07, setuploadedCac07path, setisloading);
  }, [uploadedCac07]);

  useEffect(() => {
    imageUpload(idCardOfSignatory, setidCardOfSignatorypath, setisloading);
  }, [idCardOfSignatory]);

  useEffect(() => {
    imageUpload(idCardOfSignatory2, setidCardOfSignatory2path, setisloading);
  }, [idCardOfSignatory2]);

  useEffect(() => {
    imageUpload(signatoriespassport, setsignatoriespassportpath, setisloading);
  }, [signatoriespassport]);

  useEffect(() => {
    imageUpload(
      signatoriespassport2,
      setsignatoriespassport2path,
      setisloading
    );
  }, [signatoriespassport2]);

  useEffect(() => {
    imageUpload(boardSignatory1, setboardSignatory1path, setisloading);
  }, [boardSignatory1]);

  useEffect(() => {
    imageUpload(boardSignatory2, setboardSignatory2path, setisloading);
  }, [boardSignatory2]);

  useEffect(() => {
    imageUpload(boardSignatory3, setboardSignatory3path, setisloading);
  }, [boardSignatory3]);

  useEffect(() => {
    imageUpload(companyResolution, setcompanyResolutionpath, setisloading);
  }, [companyResolution]);

  useEffect(() => {
    imageUpload(namingResolution, setnamingResolutionpath, setisloading);
  }, [namingResolution]);
  useEffect(() => {
    imageUpload(utilBill, setutilBillpath, setisloading);
  }, [utilBill]);

  useEffect(() => {
    imageUpload(uploadedSignature, setSignatureUrl, setisloading);
  }, [uploadedSignature]);

  useEffect(() => {
    imageUpload(uploadedimage, setPassportPath, setisloading);
  }, [uploadedimage]);

  return (
    <Spin
      spinning={isloading}
      delay={500}
      size="large"
      tip={"Fetching Table Data...."}
      className=" text-[#9b7c27] font-bold"
    >
      <div className="lg:w-[70%] w-[90%]  mx-auto pt-20 pb-10">
        <Steps current={current} items={steps} />
        <div>{steps[current].content}</div>

        <div>
          {current > 0 && (
            <Button
              className="bg-[#9b7c27] text-white w-[30%] border my-5 "
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
        <Modal
          open={isModalOpen}
          centered={true}
          // okText="save"
          cancelText="Make Changes"
          // onOk={actionDeleteHandler}
          onCancel={OnCancelHandler}
          footer={null}
        >
          <div className=" w-[80%] mx-auto flex flex-col justify-center items-center text-center gap-5">
            <GrValidate className="text-[40px] text-[green] " />
            <p className="text-[18px] leading-[21px] flex flex-col font-bold gap-1 ">
              Details Successfully Submitted
            </p>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default CoporateAccount;
