import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/HomePage/Homepage";
import HomePageIndex from "./pages/HomePage/HomePageIndex";
import AboutUsHome from "./pages/AboutUsPage/AboutUsHome";
import ProductHomePage from "./pages/ProductsPage/ProductHomePage";
import ServicesHomePage from "./pages/ServicesPage/ServicesHomePage";
import ResearchHomePage from "./pages/ResearchPage/ResearchHomePage";
// import IndividualAccount from "./pages/IndividualAccount/IndividualAccount";
import TermsAndConditionPage from "./pages/TermsAndCondition/TermsAndConditionPage";
import FormsPage from "./pages/AccountOpening";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePageIndex />}>
        <Route index element={<Homepage />} />
        <Route path="AboutUs/*" element={<AboutUsHome />} />
        <Route path="Products/*" element={<ProductHomePage />} />
        <Route path="what-we-do/*" element={<ServicesHomePage />} />
        <Route path="Research/*" element={<ResearchHomePage />} />
       
        <Route path=":id" element={< FormsPage/>} />
        <Route path="contact" element={<ContactUs/>} />
        <Route path="Terms&Condition" element={<TermsAndConditionPage />} />
       
      </Route>
    
    </Routes>
  );
}

export default App;
