import apiInstance from "./config";

const adminServices = { };

adminServices.getAllSlider = ()=> apiInstance({
    url: `/Slider`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.MissionVision = ()=> apiInstance({
    url: `/MissionVision`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.getAllFormType = ()=> apiInstance({
    url: `/AppData/FormType`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.GetAllStocks = ()=> apiInstance({
    url: `/v1/StockMarket/list`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllCountry = ()=> apiInstance({
    url: `/AppData/Country`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllState = ()=> apiInstance({
    url: `/AppData/States`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllBanks = ()=> apiInstance({
    url: `/AppData/Banks`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.createIndividualForm = (data)=> apiInstance({
    url: `/PersonalDetails/PersonalDetails`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.personalDetails = (data)=> apiInstance({
    url: `/AccountOpening/PersonalDetails`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.companyReg = (data)=> apiInstance({
    url: `/AccountKYC/Company`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.PostKycDetails = (data)=> apiInstance({
    url: `/AccountKYC/NextOfKin`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.guardianDetails = (data)=> apiInstance({
    url: `/AccountKYC/Guardian`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.PostBankDetails = (data)=> apiInstance({
    url: `/AccountKYC/BankDetails`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.AccountOpening = (data)=> apiInstance({
    url: `/AccountOpening`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.ContactMail = (data)=> apiInstance({
    url: `/SendMail`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.getAllBoards = ()=> apiInstance({
    url: `/ManagementTeams`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllPost = ()=> apiInstance({
    url: `/Post`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.fileUpload = (data)=> apiInstance({
    url: `/AppData/FileUpload`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'multipart/form-data',
      },
})




export default adminServices