import { TiChevronRightOutline } from "react-icons/ti";
import products_banner from "../../Assets/about-banner2.jpg";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import { MdMarkEmailRead } from "react-icons/md";
import { Button, Checkbox, Form, Input } from "antd";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import { useState } from "react";
const ContactUs = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isloading, setisLoading] = useState(false);

  const Onfinish = async () => {
    delete values?.name;
    delete values?.number;
    delete values?.TermsandConditions;

    try {
      setisLoading(true);
      const res = await adminServices.ContactMail(values);
      //console.log(res);
      if (res?.status === 200) {
        NotificationService.show("message successfully sent", "success");
        setisLoading(false);
        form.resetFields();
      }
    } catch (error) {
      setisLoading(false);
      NotificationService.show(error.message, "error");
    }
  };
  return (
    <div className="">
      <div
        className="bg-cover bg-[center] bg-no-repeat flex justify-center flex-col items-center h-[45vh] gap-10"
        style={{
          backgroundImage: `url(${products_banner})`,
        }}
      >
        <p className="  text-[41px]  text-white font-[400] flex_center flex-col capitalize">
          Contact Us
          <span className="border-[1px] w-[20%]"></span>
        </p>

        <ul className="flex_center text-white gap-3 font-[600] leading-loose">
          <li>
            <Link className="flex justify-center items-center gap-1" to="/">
              Home <TiChevronRightOutline />
            </Link>
          </li>
          <li>
            <Link
              className="flex justify-center items-center gap-1"
              to="/contact/"
            >
              Contact Us <TiChevronRightOutline />
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex flex-col lg:flex-row w-[95%] gap-10 md:w-[90%] mx-auto my-10">
        <div className="md:pl-10 px-5 md:px-0 py-5  rounded-[8px] bg-[#1F2134]  flex flex-col justify-start items-start gap-5  lg:w-[30%]">
          <p className="text-[24px] text-[#B49132] font-semibold pb-5">
            Quick Contact
          </p>

          <div className="flex flex-col gap-2  w-full ">
            <p className="text-[18px] text-[#B49132] font-[500] flex justify-start items-center gap-1">
              {" "}
              <FaLocationDot className="text-[22px]" /> Locations
            </p>
            <div className="flex flex-col justify-start items-start gap-1  text-white text-[14px] w-full  ">
              <div className="flex  justify-start items-center  w-full">
                <p className="font-[500] text-[16px] w-[25%] ">Lagos :</p>
                <p className="font-[400] text-[14px] w-[70%] ">
                  15, Onikoyi Road , Ikoyi , Lagos
                </p>
              </div>

              <div className="flex  justify-start items-center  w-full">
                <p className="font-[500] text-[16px] w-[25%] ">Aba :</p>
                <p className="font-[400] text-[14px] w-[70%] ">
                  11, Oron Road , Aba , Abia State
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 ">
            <p className="text-[18px] text-[#B49132] font-[500] flex justify-start items-center gap-1">
              {" "}
              <IoMdCall className="text-[22px]" /> Call Us
            </p>
            <div className="flex flex-col gap-1  text-white text-[14px]">
              <p>070-5387-2334</p>
              <p>080-3764-1335</p>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[18px] text-[#B49132] font-[500] flex justify-start items-center gap-1">
              {" "}
              <MdMarkEmailRead className="text-[22px]" /> Email
            </p>
            <div className="flex flex-col gap-1  text-white text-[14px]">
              <Link to={"mailto:info@heritagecapitals.com.ng"}>info@heritagecapitals.com.ng</Link>
            </div>
          </div>
        </div>

        <div className="lg:w-[70%]  rounded-[8px]">
          <Form
            form={form}
            // initialValues={initialValues}
            onFinish={Onfinish}
            layout="vertical"
            className="bg-gray-50 p-5  rounded flex flex-col gap-5 shadow-2xl text-[#1F2134]"
          >
            <div className="flex flex-col justify-start items-start gap-1">
              <p className="text-[24px] font-semibold">Let’s Talk!</p>
              <p className="text-[16px] font-[500] text-[#B49132]">
                Contact us to get, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ainim veniam.
              </p>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter  Name" }]}
                className="mb-1 flex-1"
                name="name"
                label="Name"
              >
                <Input className="!h-[40px]" placeholder="Name" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Your Email" }]}
                className="mb-1 flex-1"
                name="email"
                label="Email"
              >
                <Input className="!h-[40px]" placeholder="Email" type="email" />
              </Form.Item>
            </div>

            <div className="flex gap-3 flex-col md:flex-row ">
              <Form.Item
                rules={[{ required: true, message: "Enter  Phone Number" }]}
                className="mb-1 flex-1"
                name="number"
                label="Phone Number"
              >
                <Input className="!h-[40px]" placeholder="Enter Phone Number" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter Subject" }]}
                className="mb-1 flex-1"
                name="subject"
                label="Subjects"
              >
                <Input className="!h-[40px]" placeholder="Subjects" />
              </Form.Item>
            </div>
            <Form.Item
              rules={[{ required: true, message: "Write your message " }]}
              className="mb-1 flex-1"
              name="message"
              label="Write something"
            >
              <Input.TextArea placeholder="Write something" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Terms and Conditions" }]}
              className="mb-1 flex-1"
              name="TermsandConditions "
              label=""
            >
              <div className="flex gap-3">
                <Checkbox />
                <p>
                  I agree with the{" "}
                  <span>
                    <Link to={"/Terms&Condition"}>Terms and Conditions</Link>{" "}
                  </span>
                </p>
              </div>
            </Form.Item>

            <Button
              htmlType="submit"
              className="bg-[#1F2134] font-semibold text-[#B49132]"
              loading={isloading}
            >
              Contact Us
            </Button>
          </Form>
        </div>
      </div>

      <div className=" w-full ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.4964241751786!2d3.4404044842449557!3d6.458611207256605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf4ec987a9e0b%3A0x89b8435de8623591!2sHeritage%20Capital%20Markets%20Limited!5e0!3m2!1sen!2sng!4v1708899487924!5m2!1sen!2sng"
          width="800"
          height="500"
          className="w-full"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
