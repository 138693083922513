import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditionPage = () => {
  return (
    <div className="flex justify-between items-start gap-20 w-[80%] mx-auto my-10 shadow-2xl p-8">
      <div className="flex flex-col justify-center items-center  leading-loose w-full">
        <p className="text-[18px] font-bold">TERMS AND CONDITIONS</p>
        <div className="flex flex-col text-justify">
          <p>
            a. HCML offices are open between the hours of 8 a.m. and 5 p.m. on
            each day that is designated a Business day in Nigeria (“Business
            Day”)
          </p>
          <p>
            b. Client advices and mandates should be sent physically to HCML
            office in Lagos or other office, as HCML may from time to time
            maintain elsewhere in Nigeria or via e-mail and SMS. The dedicated
            e-mail address for mandates is{" "}
            <span className="text-[blue] cursor-pointer font-[600]">
              <Link to={'mailto:mandates@heritagecapitals.com.ng'}>mandates@heritagecapitals.com.ng</Link>
            </span> ,
            <span className="text-[blue] cursor-pointer font-[600]">
              <Link to={'mailto:info@heritagecapitals.com.ng'}>info@heritagecapitals.com.ng </Link>
            </span>
            and the number for SMS mandate is
            <span className="text-[blue] cursor-pointer font-[600]">
              <Link> 07013113933</Link>
            </span>
          </p>
          <p>
            c. The daily transaction deadline for the receipt of stock mandates
            and/or amendments from clients is 10 a.m. (Nigerian Time) of the
            Business Day of which such Mandate is to be executed
          </p>
          <p>
            d. Where a mandate consists of a purchase instruction, it can only
            be processed if sufficient cleared funds are available to execute
            such an instruction and prevailing market conditions.
          </p>
          <p>
            e. Valid mandates have an execution period of five (5) Business Days
            unless otherwise specified by the client after which they will be
            deemed to have expired.
          </p>
          <p>
            f. The client is liable to pay all applicable statutory charges on
            transactions executed on the floors of various Stock Exchanges as
            may be advised from time to time by the regulatory authorities.
            Please confirm the ruling charges from the Customer Service Officers
            or through the Customer Service listed elsewhere on this form.
          </p>
          <p>
            g. All other negotiated transactions consummated outside the floors
            of the Stock Exchanges shall attract charges as will be specifically
            agreed between the parties in writing.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-10 leading-loose w-full">
        <p>
          <span className="text-[18px] font-bold p-1">N.B : </span>
          All deletions and/or alterations must be initiated by the Client. The
          Mandate must be initialed by all signatories hereto.
        </p>

        <div className="flex justify-center items-center gap-2 flex-col">
          <p className="text-[18px] font-bold">ANTI-MONEY LAUNDERING ACT</p>
          <div className="flex flex-col gap-3">
            <p>
              To help the Federal Government of Nigeria fight the funding of
              terrorism and money laundering activities, the Money laundering
              activities, the Money Laundering (Prohibition) Act 2013 requires
              all financial and non- financial institutions to obtain, verify
              and record information that identifies each person who opens an
              account. In this regard, when you open an account with us at HCML,
              we will ask for your name, address, means of identification,
              occupation and other information that will enable us to identify
              you and verify your identity on an ongoing basis.
            </p>

            <p>
              Furthermore, in compliance with the Money Laundering (Prohibition)
              Act 2013, any single transaction, lodgement or transfer of funds
              in excess of ₦5,000,000.00 or its equivalent in the case of
              individual or ₦10,000,000.00 or its equivalent in the case of
              corporate body shall be reported in writing within seven (7) days
              to the Nigerian Financial Intelligence Unit (NFIU) and the
              functions of the Securities & Exchange Commission (as applicable)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
